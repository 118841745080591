/*eslint-disable jsx-a11y/anchor-is-valid*/

import * as React from 'react'
import footer from 'src/content/footer'
import { scrollTo } from 'src/utils'

const Footer = () => {
  return (
    <nav className="footer">
      {footer.links &&
        footer.links.map(({ linkText, selector, linkURL }, i) => (
          <a
            key={i}
            href={linkURL}
            target="_blank"
            rel="noreferrer"
            onClick={(event) => {
              if (linkURL) return
              event.preventDefault()
              const section = document.querySelector(selector)
              scrollTo(section)
            }}
          >
            {linkText}
          </a>
        ))}
    </nav>
  )
}

export default Footer
