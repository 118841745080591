/*eslint-disable jsx-a11y/click-events-have-key-events*/
/*eslint-disable jsx-a11y/no-static-element-interactions*/
/*eslint-disable jsx-a11y/no-noninteractive-element-interactions*/

import React from 'react'
import closeBtn from 'src/images/close-drawer-btn.svg'
import { useStore } from 'src/state'
import c from 'classnames'

const Drawer = ({ className }) => {
  const { closeDrawer, shouldShowDrawer, drawerContent } = useStore()

  return (
    <div className={c('drawer', shouldShowDrawer && 'visible', className)}>
      <div className="bg" onClick={closeDrawer} />
      <div className="drawer-content">
        {drawerContent}
        <img
          className="close-btn"
          src={closeBtn}
          alt="Close Button"
          onClick={closeDrawer}
        />
      </div>
    </div>
  )
}

export default Drawer
