import React, { useState } from 'react'
import c from 'classnames'
import VisibilitySensor from 'react-visibility-sensor'

const Section = ({ className, children }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <VisibilitySensor
      offset={{ bottom: 200 }}
      partialVisibility
      onChange={(visible) => setIsVisible(visible)}
    >
      <section className={c('section', isVisible && 'in-view', className)}>
        <div className="section-content">{children}</div>
      </section>
    </VisibilitySensor>
  )
}

export default Section
