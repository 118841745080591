/*eslint-disable jsx-a11y/click-events-have-key-events*/
/*eslint-disable jsx-a11y/no-static-element-interactions*/

import * as React from 'react'
import partners from 'src/content/partners'
import Section from 'src/components/section'
import { useStore } from 'src/state'
import { toHtml } from 'src/utils'

const Partners = () => {
  const { showDrawer, setDrawerContent } = useStore()

  return (
    <Section className="partners">
      <h2>{partners.title}</h2>
      <div className="partners">
        {partners.partners.map(({ logo, linkText, drawerTitle, copy }, i) => (
          <div
            className="partner"
            key={i}
            onClick={() => {
              setDrawerContent(
                <div className="partners-drawer-content">
                  <h1>{drawerTitle}</h1>
                  {toHtml(copy)}
                </div>
              )
              showDrawer()
            }}
          >
            <img src={logo} alt="partner" />
            <button>{linkText}</button>
          </div>
        ))}
      </div>
    </Section>
  )
}

export default Partners
