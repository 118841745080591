import React from 'react'
import c from 'classnames'

const Loader = ({ className }) => {
  return (
    <div className={c('loader', className)}>
      <div className="bar" />
    </div>
  )
}

export default Loader
