/*eslint-disable react-hooks/exhaustive-deps*/

import React, { useState, useEffect, useRef } from 'react'
import winners from 'src/content/winners'
import Section from 'src/components/section'
import c from 'classnames'

const Winners = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [height, setHeight] = useState(0)
  const images = useRef()

  const nextImage = () => {
    setCurrentIndex((currentIndex) => {
      let nextIndex = currentIndex + 1
      if (nextIndex >= winners.images.length) nextIndex = 0
      return nextIndex
    })
  }

  useEffect(() => {
    const element = images.current
    const resizeObserver = new ResizeObserver(() => {
      const width = element && element.offsetWidth
      const ratio = 1200 / 1920
      const height = width * ratio
      setHeight(height)
    })
    resizeObserver.observe(element)
    return () => element && resizeObserver.observe(element)
  }, [images])

  useEffect(() => {
    const interval = setInterval(nextImage, 5000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Section className={c('winners', !winners.visible && 'hidden')}>
      <h2>{winners.title}</h2>
      <div
        className="images"
        ref={images}
        style={{
          height: height,
        }}
      >
        {winners.images.map(({ image }, i) => (
          <img
            key={i}
            src={image}
            alt="Winners"
            className={c(i === currentIndex && 'current')}
          />
        ))}
      </div>
    </Section>
  )
}

export default Winners
