import React from 'react'
import general from 'src/content/general'

const BackgroundVideo = () => {
  return (
    general.backgroundVideo && (
      <video
        autoPlay
        muted
        loop
        preload="true"
        playsInline
        className="background-video"
      >
        <source src={general.backgroundVideo} type="video/mp4"></source>
      </video>
    )
  )
}

export default BackgroundVideo
