import * as React from 'react'
import tournament from 'src/content/tournament'
import Section from 'src/components/section'
import Streak from 'src/components/streak'
import { useStore } from 'src/state'
import { toHtml } from 'src/utils'

const Tournament = () => {
  const { showDrawer, setDrawerContent } = useStore()

  return (
    <Section className="tournament">
      <div className="heading">
        <Streak />
        <h2>{tournament.title}</h2>
        <div className="copy">{tournament.copy}</div>
      </div>

      <div className="year1">
        <div className="logo-container">
          <img src={tournament.year1.logo} alt="Astral clash logo" />
          <div className="year">{tournament.year1.year}</div>
        </div>

        <div className="matches">
          {tournament.year1.matches.map(
            ({ description, dates, location }, i) => (
              <div className="match" key={i}>
                <div className="description">{description}</div>
                <div className="dates">{dates}</div>
                {location && <div className="location">{location}</div>}
              </div>
            )
          )}
          <button
            onClick={() => {
              setDrawerContent(
                <div className="tournament-drawer-content">
                  <h1>{tournament.year1.learnMore.title}</h1>
                  {toHtml(tournament.year1.learnMore.copy)}
                </div>
              )
              showDrawer()
            }}
          >
            {tournament.year1.learnMore.linkText}
          </button>
        </div>
      </div>

      <div className="year2">
        <div className="logo-container">
          <img src={tournament.year2.logo} alt="Astral clash logo" />
          <div className="year">{tournament.year2.year}</div>
        </div>

        <div className="matches">
          {tournament.year2.matches.map(
            ({ description, dates, location }, i) => (
              <div className="match" key={i}>
                <div className="description">{description}</div>
                <div className="dates">{dates}</div>
                {location && <div className="location">{location}</div>}
              </div>
            )
          )}
          <button
            onClick={() => {
              setDrawerContent(
                <div className="tournament-drawer-content">
                  <h1>{tournament.year2.learnMore.title}</h1>
                  {toHtml(tournament.year2.learnMore.copy)}
                </div>
              )
              showDrawer()
            }}
          >
            {tournament.year2.learnMore.linkText}
          </button>
        </div>
      </div>
    </Section>
  )
}

export default Tournament
