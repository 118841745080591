/*eslint-disable jsx-a11y/anchor-is-valid*/
/*eslint-disable jsx-a11y/click-events-have-key-events*/
/*eslint-disable jsx-a11y/no-noninteractive-element-interactions*/
/*eslint-disable jsx-a11y/no-static-element-interactions*/

import React from 'react'
import header from 'src/content/header'
import { scrollTo } from 'src/utils'
import { useStore } from 'src/state'

const Header = () => {
  const { closeDrawer, showDrawer, setDrawerContent } = useStore()

  return (
    <header>
      <div className="desktop">
        <nav>
          {header.logosLeft &&
            header.logosLeft.map(({ logo, logoURL }, i) => (
              <a key={i} href={logoURL} target="_blank" rel="noreferrer">
                <img src={logo} alt="Logo" />
              </a>
            ))}
          <div className="spacer"></div>
          {header.links &&
            header.links.map(({ linkText, selector }, i) => (
              <a
                key={i}
                href={''}
                rel="noreferrer"
                className="link"
                onClick={(event) => {
                  event.preventDefault()
                  const section = document.querySelector(selector)
                  scrollTo(section)
                }}
              >
                {linkText}
              </a>
            ))}
          <div className="spacer"></div>
          {header.logosRight &&
            header.logosRight.map(({ logo, logoURL }, i) => (
              <a key={i} href={logoURL} target="_blank" rel="noreferrer">
                <img src={logo} alt="Logo" />
              </a>
            ))}
        </nav>
      </div>
      <div className="mobile">
        <div
          className="menu-btn"
          onClick={() => {
            setDrawerContent(
              <div className="header-links">
                {header.links.map(({ linkText, selector }, i) => (
                  <a
                    key={i}
                    href={''}
                    rel="noreferrer"
                    className="link"
                    onClick={(event) => {
                      event.preventDefault()
                      const section = document.querySelector(selector)
                      scrollTo(section)
                      closeDrawer()
                    }}
                  >
                    {linkText}
                  </a>
                ))}
              </div>
            )
            showDrawer(true)
          }}
        >
          Menu
        </div>
      </div>
    </header>
  )
}

export default Header
