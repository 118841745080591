import React from 'react'
import c from 'classnames'

const Streak = ({ className }) => {
  return (
    <div className={c('streak', className)}>
      <div className="inner" />
    </div>
  )
}

export default Streak
