import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import parse from 'html-react-parser'

export const scrollTo = (node) => {
  scrollIntoView(node, {
    duration: 700,
    block: 'start',
  })
}

export const toHtml = (str) => {
  try {
    return parse(str)
  } catch (err) {
    return ''
  }
}
