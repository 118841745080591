import * as React from 'react'
import ReactMarkdownWithHtml from 'react-markdown'
import sweepstakes from 'src/content/sweepstakes'
import rightArrow from 'src/images/right-arrow.svg'
import Section from 'src/components/section'
import Streak from 'src/components/streak'

const Sweepstakes = () => {
  return (
    <Section className="sweepstakes">
      <div className="heading">
        <h2>{sweepstakes.title}</h2>
        <ReactMarkdownWithHtml children={sweepstakes.copy} allowDangerousHtml />
        <img src={sweepstakes.image} alt="Prizes" className="desktop" />
        <img src={sweepstakes.mobileImage} alt="Prizes" className="mobile" />
        <a href={sweepstakes.linkURL} target="_blank" rel="noreferrer">
          {sweepstakes.linkText} <img src={rightArrow} alt="Right arrow" />
        </a>
      </div>
      <div className="giveaways">
        <Streak />
        <div className="grand-prize">
          <div className="description">
            {sweepstakes.grandPrizeGivaway.description}
          </div>
          {sweepstakes.grandPrizeGivaway.giveaways.map(
            ({ dates, description }, i) => (
              <div className="giveaway" key={i}>
                <div className="dates">{dates}</div>
                <div className="description">{description}</div>
              </div>
            )
          )}
        </div>
        <div className="monthly">
          <div className="description">
            {sweepstakes.monthlySweepstakes.description}
          </div>
          {sweepstakes.monthlySweepstakes.giveaways.map(
            ({ dates, description }, i) => (
              <div className="giveaway" key={i}>
                <div className="dates">{dates}</div>
                <div className="description">{description}</div>
              </div>
            )
          )}
        </div>
      </div>
    </Section>
  )
}

export default Sweepstakes
