import * as React from 'react'
import campaignOverview from 'src/content/campaignOverview'
import Section from 'src/components/section'
import { toHtml } from 'src/utils'

const CampaignOverview = () => {
  return (
    <Section className="campaign-overview">
      <h2>{campaignOverview.title}</h2>
      <div className="copy">{toHtml(campaignOverview.copy)}</div>
      <div className="ellipse"></div>
    </Section>
  )
}

export default CampaignOverview
