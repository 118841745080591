import React, { useState, useEffect } from 'react'
import hero from 'src/content/hero'
import Section from 'src/components/section'
import c from 'classnames'

const Hero = () => {
  const [animateIn, setAnimateIn] = useState(false)
  const [hasAnimatedIn, setHasAnimatedIn] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setAnimateIn(true)
      setTimeout(() => {
        setAnimateIn(false)
        setHasAnimatedIn(true)
      }, 1500)
    }, 500)
  }, [])
  return (
    <Section className="hero in-view">
      <img
        className={c(
          'hero-image',
          animateIn && 'animate-in',
          hasAnimatedIn && 'has-animated-in'
        )}
        src={hero.astralClashLogo}
        alt="Hero logo"
      />
      <div
        className={c(
          'presented-by',
          animateIn && 'animate-in',
          hasAnimatedIn && 'has-animated-in'
        )}
      >
        <div>{hero.presentedBy}</div>
        {hero.presentedByLogos &&
          hero.presentedByLogos.map(({ logo, logoURL }, i) => (
            <a key={i} href={logoURL}>
              <img src={logo} alt="Presenter logo" />
            </a>
          ))}
      </div>
      <div
        className={c(
          'frame',
          animateIn && 'animate-in',
          hasAnimatedIn && 'has-animated-in'
        )}
      ></div>
    </Section>
  )
}

export default Hero
