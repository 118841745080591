import * as React from 'react'
import ambassadors from 'src/content/ambassadors'
import Section from 'src/components/section'
import Streak from 'src/components/streak'

const Ambassadors = () => {
  return (
    <Section className="ambassadors">
      <h2>{ambassadors.title}</h2>

      <div className="person host">
        <img src={ambassadors.host.photo} alt="host portrait" />
        <p>{ambassadors.host.name}</p>
      </div>

      <div className="teams">
        <div className="team-a">
          <div className="team">
            <img src={ambassadors.teamA.logo} alt="Team A logo" />
            <p>{ambassadors.teamA.description}</p>
          </div>
          {(ambassadors.teamA.members || []).map(({ name, photo }, i) => (
            <div className="person" key={i}>
              <img src={photo} alt={name} />
              <p>{name}</p>
            </div>
          ))}
        </div>

        <div className="versus">{ambassadors.versus}</div>

        <div className="team-b">
          <div className="team">
            <img src={ambassadors.teamB.logo} alt="Team B logo" />
            <p>{ambassadors.teamB.description}</p>
          </div>
          {(ambassadors.teamB.members || []).map(({ name, photo }, i) => (
            <div className="person" key={i}>
              <img src={photo} alt={name} />
              <p>{name}</p>
            </div>
          ))}
        </div>
      </div>
      <Streak />
    </Section>
  )
}

export default Ambassadors
