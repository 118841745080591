/*eslint-disable jsx-a11y/media-has-caption*/

import React, { useState, useEffect } from 'react'
import c from 'classnames'
import general from 'src/content/general'
import Header from 'src/components/header'
import Hero from 'src/components/hero'
import CampaignOverview from 'src/components/campaign-overview'
import Ambassadors from 'src/components/ambassadors'
import Tournament from 'src/components/tournament'
import Sweepstakes from 'src/components/sweepstakes'
import Winners from 'src/components/winners'
import Partners from 'src/components/partners'
import Footer from 'src/components/footer'
import Loader from 'src/components/loader'
import BackgroundVideo from 'src/components/background-video'
import Drawer from 'src/components/drawer'

const IndexPage = () => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true)
    }, 1000)
  }, [setIsReady])

  return (
    <>
      <main className={c(isReady && 'visible')}>
        <title>{general.siteName}</title>
        <BackgroundVideo />
        <div className="sections">
          <Header></Header>
          <Hero></Hero>
          <CampaignOverview></CampaignOverview>
          <Ambassadors></Ambassadors>
          <Tournament></Tournament>
          <Sweepstakes></Sweepstakes>
          <Winners></Winners>
          <Partners></Partners>
          <Footer></Footer>
        </div>
        <Drawer />
      </main>
      <Loader className={c(isReady && 'hidden')} />
    </>
  )
}

export default IndexPage
